export const PUBLIC_GOOGLE_ANALYTICS = 'G-783183RKPB';

type WindowWithGA = Window & {
  dataLayer: Array<any>;
};

function gtag(...args: any[]){
  (window as unknown as WindowWithGA).dataLayer.push(args);
}

export const pageview = (url: string) => {
  event({
    action: 'page_view',
    params: {
      page_path: url,
    }
  });
};

export const event = ({ action, params }: { action: string; params?: any; }) => {
  gtag('event', action, params)
}
